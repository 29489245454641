footer {
  --base-size: 16px;
  align-items: center;
  background-color: $darker-blue;
  display: flex;
  height: 48px;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  a {
    color: white;
    &:hover,
    &:focus {
      color: $orange;
    }
  }

  p {
    color: white;
    margin-top: 4px;
  }

  .heart {
    display: inline-block;
    cursor: crosshair;
    font-size: 0.7em;
    &:hover {
      transform: scale(1.4);
      transform-origin: center center;
    }
  }
}
