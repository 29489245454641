header {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: center;

  .header__text-wrapper {
    padding-top: 96px;
    z-index: $z-high;
  }

  .header__videos-wrapper {
    background: var(--bg-color);
    align-items: center;
    justify-content: center;
    padding-top: 48px;
    max-width: 560px * 2;
    width: 50vw;
    position: relative;

    &::before {
      content: '';
      display: block;
      background-color: $orange;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      max-width: calc(560px * 1.6);
      width: 50vw;
      height: 10px;
      position: absolute;
      bottom: -10px;
    }
  }

  .video__andy,
  .video__michelle {
    border-radius: 8px;
    max-width: 560px;
    width: 40vw;
  }

  .video__andy {
    border-left-color: white;
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .video__michelle {
    border-right-color: white;
    border-right-width: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mimics-h1 {
    color: var(--text-color);
  }

  .mimics-p {
    color: var(--text-color);
    display: block;
    padding-top: 48px;
    text-transform: capitalize;
    @include medium-screen {
      padding-right: 0;
      padding-left: 0;
    }
  }

  span,
  sup {
    color: inherit;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .day,
  .month,
  .year,
  p {
    background-color: var(--bg-color);
    padding: 0 6px;
    @include medium-screen {
      background-color: transparent;
      padding: 0;
    }
  }

  .day {
    display: inline-flex;
    z-index: 10;
  }

  sup {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 0.3em;
    font-style: italic;
    height: 1.8em;
    justify-content: center;
    margin-top: 0.5em;
    text-transform: initial;
    width: 1.8em;
  }

  sup,
  .fancy {
    color: $orange;
  }
}
