:root {
  color-scheme: light dark;
}

body,
body.light-mode {
  --text-color: #{$dark-blue};
  --bg-color: white;
  --accent: #{$offwhite};
}
body.dark-mode {
  --text-color: #{$offwhite};
  --bg-color: #{$darker-blue};
  --accent: #{$dark-blue};
  .video__andy,
  .video__michelle {
    opacity: 0.8;
  }
}

@include prefers-dark {
  body,
  body.dark-mode {
    --text-color: #{$offwhite};
    --bg-color: #{$darker-blue};
    --accent: #{$dark-blue};
    .video__andy,
    .video__michelle {
      opacity: 0.8;
    }
  }
  body.light-mode {
    --text-color: #{$darker-blue};
    --bg-color: white;
    --accent: #{$offwhite};
  }
}
