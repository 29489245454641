@mixin full-height() {
  height: calc(100vh - 48px);
  @include medium-screen {
    height: calc(100vh - 92px);
  }
}
.photos {
  background-color: var(--text-color);
  @include full-height();
}

.glide {
  @include full-height();
  position: relative;
}

[data-glide-el='controls'] {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 2em);
  width: calc(100vw - 16px);
  z-index: $z-high;
}

[data-glide-el='controls'] [data-glide-dir] {
  opacity: 0.7;
  @include focus-hover {
    opacity: 1;
  }
}

[data-glide-el='controls'] > [data-glide-dir='<'] {
  @include focus-hover {
    padding-left: 3em;
  }
}

[data-glide-el='controls'] > [data-glide-dir='>'] {
  @include focus-hover {
    padding-right: 3em;
  }
}
.glide__slide {
  @include full-height();
}

.glide__slide {
  width: calc(100vw - 48px);
  @include medium-screen {
    width: calc(100vw - 96px);
  }
  > img {
    @include full-height();
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
