// Visually hide an element while allowing it to still be utlized by a screenreader
@mixin sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  margin: -1px;
  padding: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
}

.sr-only {
  @include sr-only();
}

.rotate-90 {
  display: block;
  transform: rotate(90deg);
}

.rotate-180 {
  display: block;
  transform: rotate(180deg);
}

@mixin button-reset {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;

  &[disabled] {
    cursor: default;
  }
}

.button-reset {
  @include button-reset;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.hidden {
  display: none;
  visibility: none;
  opacity: 0;
}
