.nav {
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: 48px;
  width: 100%;
  z-index: $z-top;

  a {
    color: var(--text-color);

    &:focus,
    &:hover {
      color: $orange;
    }
    display: block;
    @include default-font;
    padding: 0.5em;
    text-decoration: none;
  }

  .btn-nav-toggle {
    background-color: var(--bg-color);
    border-color: transparent;
    border-radius: 0;
    display: none;
    font-size: 1em;
    margin: 0;
    padding: 8px 12px;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;

    * {
      color: var(--text-color);
    }

    span {
      width: 24px;
      height: 24px;
    }

    &:focus,
    &:hover {
      background: var(--accent);
      border-radius: 0;
      color: var(--text-color);
      cursor: pointer;
    }
  }

  ul {
    display: none;
  }

  li {
    padding-bottom: 1em;
  }

  .btn-nav-toggle {
    display: block;
  }

  ul.is-active {
    display: block;
    margin-top: 48px;
  }

  .btn-dark-toggle {
    background-color: transparent;
    border-color: rgba(var(--text-color), 0.2);
    border-style: solid;
    border-width: 1px;
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.7em;
    letter-spacing: 0.02em;
    max-height: 2em;
    padding: 2px 8px;
    text-transform: uppercase;

    &:focus,
    &:hover {
      color: $orange;
      border-color: $orange;
    }
  }

  @media (min-width: 700px) {
    li {
      display: inline-block;
      margin-right: 1em;
      padding-bottom: 0;
    }

    ul {
      display: block;
    }

    .btn-nav-toggle {
      display: none;
    }
  }
}
