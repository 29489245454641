html {
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
}

address {
  font-style: normal;
}
