// Media queries
@mixin medium-screen() {
  @media (min-width: 540px) {
    @content;
  }
}

@mixin large-screen() {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xlarge-screen() {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin prefers-dark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin prefers-light {
  @media (prefers-color-scheme: light) {
    @content;
  }
}

// Focus and hover
@mixin focus-hover() {
  &:focus,
  &:hover {
    @content;
  }
}

// Transitions
@mixin default-transition {
  transition: all 150ms ease-in-out;
}

// Display
@mixin display($value) {
  /// @param  { string }  $value - valid value for the "display" property
  /// @see $display-values below or https://developer.mozilla.org/en-US/docs/Web/CSS/display
  /// @example
  ///  .example {
  ///    @include display(block);
  ///  }
  /// @output CSS
  ///  .example {
  ///    display: block;
  ///  }

  display: $value;
}

$display-values: (
  'block': block,
  'flex': flex,
  'grid': grid,
  'inline': inline,
  'inline-block': inline-block,
  'inline-flex': inline-flex,
  'none': none,
);

@each $value-name, $value in $display-values {
  /// @output CSS classes: .block, .flex, .grid, .inline, .inline-block, .inline-flex, none

  .#{$value-name} {
    @include display($value);
  }
}

// Grid
/*
.grid-split =
----------------------------------------------------------------
|       .grid-area-left      |      .grid-area-right       |
----------------------------------------------------------------
|                      .grid-area-bottom                     |
----------------------------------------------------------------
*/

@mixin grid-split {
  .grid-split {
    @include medium-screen {
      align-items: start;
      display: grid;
      grid-template-areas:
        'grid-area-left grid-area-right'
        'grid-area-bottom grid-area-bottom';
      gap: 1% 2%;
      grid-template-columns: 49% auto;
    }
    @include large-screen {
      gap: 1% 4%;
      grid-template-columns: 48% auto;
    }
  }
}
@include grid-split;

/*
.grid-right-lg =
------------------------------------------------------------
| .grid-area-left |          .grid-area-right          |
------------------------------------------------------------
|                     .grid-area-bottom                  |
------------------------------------------------------------
*/
@mixin grid-right-lg {
  .grid-right-lg {
    // using custom media query here since the right side gets cut off and horizontally scrolls
    @media (min-width: 739px) {
      display: grid;
      grid-template-areas:
        'grid-area-top grid-area-top'
        'grid-area-left grid-area-right'
        'grid-area-bottom grid-area-bottom';
      gap: 24px 8%;
      grid-template-columns: auto 70%;
      grid-template-rows: minmax(mincontent, 100%);
      max-width: 1200px;
    }
  }
}
@include grid-right-lg;

// Area classes
.grid-area-top {
  grid-area: grid-area-top;
}

.grid-area-left {
  grid-area: grid-area-left;
}

.grid-area-right {
  grid-area: grid-area-right;
}

.grid-area-bottom {
  grid-area: grid-area-bottom;
}
