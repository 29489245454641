button {
  @include button-reset;
  background-color: var(--text-color);
  border-radius: 2px;
  color: var(--bg-color);
  cursor: pointer;
  padding: 0.5em 2em;

  @include focus-hover {
    background-color: var(--accent);
    color: var(--text-color);
    border-radius: 8px;
  }
}
