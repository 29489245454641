section:not(.photos) {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 16px 2em;
  width: calc(100% - 2em);
  @include medium-screen {
    padding: 2em 6em;
    width: calc(100% - 6em);
  }
}

section:not(.photos) {
  width: 100%;

  a {
    display: inline-block;
    text-decoration: none;
  }

  > h2 {
    padding-top: 96px;
  }
}

.basic-info_content {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include medium-screen {
    flex-direction: row;
  }
}

.basic-info_location,
.basic-info_time,
.basic-info_attire {
  margin-bottom: 4em;
}

.basic-info_location,
.basic-info_time {
  margin-right: 2em;
  @include large-screen {
    margin-right: 2.5em;
  }
}

section.schedule {
  padding-bottom: 10em;
}

section.yay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 48px - 1em); // viewheight minus footer minus padding
}
