$max-width: 540px;

form,
label,
input,
select,
textarea {
  border-radius: 2px;
  margin: 0;
  padding: 0;

  @include focus-hover {
    border-radius: 8px;
    outline-color: $orange;
  }
}

.radio-group {
  display: flex;
}

[name="rsvp"] {

  @include large-screen {
    > ul {
      display: flex;
      align-items: space-between;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  > ul > li {
    margin-bottom: 1em;
    width: 100%;
  }
}

.rsvp__attendance {
  border: 1px solid var(--text-color);
  padding: 0 1em;

  @include large-screen {
    min-width: 100%;
  }
}


@include large-screen {
  .rsvp__guest-count {
    max-width: 180px;
  }
}

.rsvp__meal-container {
  font-weight: bold;
  max-width: $max-width;

  @include large-screen {
    max-width: calc(100% - 180px - 1em); //100% - guest-count - padding
  }

  width: 100%;
}

.rsvp__meal {
  background-color: var(--accent);
  padding: 1.5em;
  margin-bottom: 1em;

  @include large-screen {
    >ul {
      display: flex;
    }

    >ul li {
      width: 100%;

      &:first-of-type {
        margin-right: 1em;
      }
    }
  }

  textarea {
    min-height: 58px;
  }
}

label:not([for^="rsvp"]) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  input:not([type="radio"]),
  select,
  textarea {
    background-color: var(--bg-color);
    border: 1px solid var(--text-color);
    color: var(--text-color);
    flex-grow: 2;
    padding: 0.5em 1em;
    max-width: $max-width;
    min-width: 16ch;
    width: 100%;

    @include large-screen {
      min-width: 100%;
    }

    &:hover,
    &:focus {
      border: 1px solid $orange;
    }
  }
}

label[for^="rsvp"] {
  cursor: pointer;
  margin: 1em 0;
  padding: 0.5em;
  width: calc(100% - 3em);

  &:first-of-type {
    margin-right: 1em;
  }

  &:hover,
  &:focus {
    background-color: var(--accent);
  }
}

.radio-group {
  max-width: $max-width;
}
