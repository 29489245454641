table {
  border-collapse: collapse;
  width: 100%;
}

thead tr {
  border-bottom: 2px solid var(--accent);
}

tbody tr {
  border-bottom: 1px solid var(--accent);
}

th {
  padding: 0.5em 1em;
  text-align: left;
}

th,
td {
  min-width: 16ch;

  @include large-screen {
    min-width: unset;
  }
}

td {
  padding: 1em;
}

.scrollable-table {
  border: 1px solid var(--accent);
  border-radius: 8px;
  margin-bottom: 3em;
  overflow-x: scroll;
  position: relative;
  width: 100%;

  @include large-screen {
    overflow-x: unset;
    height: min-content;
    width: unset;
  }
}

.scrollable-table__fade {
  background: linear-gradient(90deg, rgba(white,0) 90%, rgba($offwhite,1) 100%);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: unset;
  @include large-screen {
    display: none;
  }
}

.light-mode .scrollable-table__fade {
  background: linear-gradient(90deg, rgba(white,0) 90%, rgba($offwhite,1) 100%);
}

.dark-mode .scrollable-table__fade {
  background: linear-gradient(90deg, rgba(white,0) 97%, rgba(white,0.06) 100%);
}

@include prefers-dark {
  .dark-mode .scrollable-table__fade,
  .scrollable-table__fade {
    background: linear-gradient(90deg, rgba(white,0) 97%, rgba(white,0.06) 100%);
  }

  .light-mode .scrollable-table__fade {
    background: linear-gradient(90deg, rgba(white,0) 90%, rgba($offwhite,1) 100%);
  }
}

.scrollable-table table {
  position: absolute;
  top: 0;
  left: 0;

  @include large-screen {
    position: unset;
  }
}

.travel > .scrollable-table {
  height: calc(356px + 1.5em);
  @include large-screen {
    height: unset;
  }
}

.schedule > .scrollable-table {
  height: calc(673px + 8px);

  @media (min-width: 610px) {
    height: calc(600px + 8px);
  }

  @media (min-width: 640px) {
    height: calc(540px + 8px);
  }

  @media (min-width: 700px) {
    height: calc(500px + 8px);
  }

  @media (min-width: 780px) {
    height: calc(433px + 8px);
  }

  @include large-screen {
    height: unset;
  }

  @media (min-width: 443px) {
    .scrollable-table__fade {
      display: none;
    }
  }
}

.travel__table {
  thead th,
  tbody td {
    @include large-screen {

      &,
      a,
      p,
      span {
        font-size: calc(var(--base-size) * 0.7);
      }
    }
  }

  td:last-of-type {
    min-width: 24ch;
    padding-right: 1em;

    @include large-screen {
      min-width: 30ch;
    }
  }

  .hotels__addresses {
    min-width: 24ch;

    @include large-screen {
      min-width: none;
    }
  }
}

.schedule__table {
  th:first-of-type,
  td:first-of-type {
    min-width: unset;
  }
}
