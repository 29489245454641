// Models
@import 'reset'; // ALWAYS FIRST
@import 'variables';
@import 'helpers';
@import 'utilities';

// System
@import 'typography';
@import 'darkMode';

// Layout
@import 'nav';
@import 'header';
@import 'section';
@import 'footer';

// External
@import '../../node_modules/aos/dist/aos.css';
@import '../../node_modules/@glidejs/glide/src/assets/sass/glide.core';

// Components
@import 'buttons';
@import 'forms';
@import 'gallery';
@import 'links';
@import 'tables';

html *::selection {
  background: var(--text-color);
  color: $blue;
}

body {
  background-color: var(--bg-color);
}
