a,
.mimics-a {
  color: $orange;

  .external-link {
    color: inherit;
    display: inline-block;
    font-size: 0.6em;
    transform: translate(0.3em, -0.1em);
  }

  &:focus,
  &:hover {
    color: $blue;

    .external-link {
        color: inherit;
        transform: translate(0.4em, -0.4em);
    }
  }
}

