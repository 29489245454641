:root {
  --base-size: 16px;
  @include large-screen {
    --base-size: 24px;
  }
}
$base-size: var(--base-size);

@font-face {
  font-family: 'New York Large';
  font-style: normal;
  src: url('../fonts/eot/NewYorkLarge-Black.eot');
  src: url('../fonts/eot/NewYorkLarge-Black.eot') format('embedded-opentype'),
    url('../fonts/woff2/NewYorkLarge-Black.woff2') format('woff2'),
    url('../fonts/woff/NewYorkLarge-Black.woff') format('woff'),
    url('../fonts/ttf/NewYorkLarge-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'New York Large';
  font-style: italic;
  src: url('../fonts/eot/NewYorkLarge-BlackItalic.eot');
  src: url('../fonts/eot/NewYorkLarge-BlackItalic.eot') format('embedded-opentype'),
    url('../fonts/woff2/NewYorkLarge-BlackItalic.woff2') format('woff2'),
    url('../fonts/woff/NewYorkLarge-BlackItalic.woff') format('woff'),
    url('../fonts/ttf/NewYorkLarge-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'New York Small';
  font-style: normal;
  src: url('../fonts/eot/NewYorkSmall-Black.eot');
  src: url('../fonts/eot/NewYorkSmall-Black.eot') format('embedded-opentype'),
    url('../fonts/woff2/NewYorkSmall-Black.woff2') format('woff2'),
    url('../fonts/woff/NewYorkSmall-Black.woff') format('woff'),
    url('../fonts/ttf/NewYorkSmall-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'New York Small';
  font-style: italic;
  src: url('../fonts/eot/NewYorkSmall-BlackItalic.eot');
  src: url('../fonts/eot/NewYorkSmall-BlackItalic.eot') format('embedded-opentype'),
    url('../fonts/woff2/NewYorkSmall-BlackItalic.woff2') format('woff2'),
    url('../fonts/woff/NewYorkSmall-BlackItalic.woff') format('woff'),
    url('../fonts/ttf/NewYorkSmall-BlackItalic.ttf') format('truetype');
}

@mixin large-header-font {
  font-family: 'New York Large', Georgia, 'Times New Roman', Times, serif;
}

@mixin small-header-font {
  font-family: 'New York Small', Georgia, 'Times New Roman', Times, serif;
}

@mixin bold-default-font {
  font-family: futura-pt-bold, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

@mixin default-font {
  font-family: futura-pt, Helvetica, Arial, sans-serif;
  font-weight: 400;

  @include prefers-dark {
    font-weight: 500;
    letter-spacing: 0.01em;
  }
}

a,
.mimics-a,
body,
button,
div,
em,
form,
input,
li,
p,
.mimics-p,
select,
span,
strong,
td,
textarea {
  @include default-font;
  font-size: $base-size;
  line-height: 1.5em;
  margin: 0;
}

h1,
.mimics-h1,
h2,
.mimics-h2,
h3,
.mimics-h3 {
  @include bold-default-font;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

h1,
.mimics-h1,
h1 span,
.mimics-h1 span {
  font-size: 1.7em;
  letter-spacing: 2px;
  @include small-header-font;
  @include large-screen {
    @include large-header-font;
  }
  line-height: 1em;
}

h2,
.mimics-h2 {
  font-size: 1.5em;
  line-height: 1em;
  margin-bottom: 0.7em;
}

h3,
.mimics-h3 {
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 0.5em;
}

// Add Init Base Color
body,
p,
.mimics-p,
div,
em,
li,
span,
strong,
td,
h1,
.mimics-h1,
h2,
.mimics-h2,
h3,
.mimics-h3 {
  color: var(--text-color);
}

body * {
  color: var(--text-color);
  @include default-transition;
}

ul {
  margin-left: 1em;
}

li {
  margin-bottom: 1em;
}

p,
td {
  max-width: 48ch;
}

strong {
  @include bold-default-font();
}
